// i18next-extract-mark-ns-start google-pay-for-prestashop
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_googlepay from 'images/prestashop_googlepay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {BlogLink} from 'components/links/Blog';
import {
  BizumForPrestashopLink,
  InternalPageLink,
  PaymentsOrchestrationLink,
  PrestashopPaymentGatewayLink,
  PricingLink,
  RedsysAlternativeLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {Bold} from 'components/StickyBanner';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import {StyledDiv} from 'components/StyledSx';

const GooglePayForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="google-pay-for-prestashop"
        title={t('Google Pay for PrestaShop')}
        description={t(
          'Add the Google Pay PrestaShop integration to your store to let customers pay with one of the most popular digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the PrestaShop Google Pay integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Google Pay usage has grown in the last few years — it’s now one of the most popular
                digital wallets among consumers. Reach millions of people, improve the checkout
                experience, and sell more by adding Google Pay to your PrestaShop online store. Use
                one integration to accept and manage your entire payment stack from a single
                platform. Get MONEI.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={prestashop_googlepay}
              alt="MONEI google pay"
              title="MONEI google pay"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader sx={{maxWidth: {all: '519px'}}}>
                <Trans>Accept Google Pay payments on PrestaShop (+ more payment methods)</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink> to
                your PrestaShop store is a great start, but it’s crucial to accept a wider range of
                payment methods to reach more customers in Spain and{' '}
                <BlogLink slug="cross-border-payments">across borders</BlogLink>.
              </Trans>
              <List>
                <Trans parent="li">
                  <BlogLink slug="digital-wallet-ecommerce">Digital wallets</BlogLink> like{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,
                  and{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  <BizumForPrestashopLink>Bizum</BizumForPrestashopLink> peer-to-peer (P2P)
                  payments. (Spain)
                </Trans>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
              </List>
              <Trans parent="p">
                …and many more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <StyledDiv sx={{flex: {all: '1.1', lg: '2.9'}}}>
              <SectionHeader>
                <Trans>Increase conversion rates with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Avoid lost sales and cut down on{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                by setting up payment processing rules. Use{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink> to{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4412643058833">
                  set a default payment processor
                </AnchorLink>{' '}
                and if your chosen provider times out, we’ll route the payment to another
                top-performing one. This way, you won’t have to depend on{' '}
                <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> alone to process transactions.{' '}
                <Bold>Approve 95% more payments</Bold> and give customers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </StyledDiv>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Save money on your PrestaShop payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Sustaining a business means you need healthy profit margins. That’s why we’re the
                only{' '}
                <PrestashopPaymentGatewayLink>
                  PrestaShop payment gateway
                </PrestashopPaymentGatewayLink>{' '}
                to offer <PricingLink>scaled pricing</PricingLink>. Watch your transaction fees
                decrease in real-time as you sell more. Save money to reinvest in building your
                e-commerce business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>Set up the PrestaShop Google Pay integration</Trans>
        </SectionHeader>
        <Trans parent="p">
          Follow these steps to quickly and easily start accepting Google Pay in your PrestaShop
          store:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/360018290877-How-do-I-activate-Google-Pay-">
              Configure Google Pay payments
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            Follow the{' '}
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop/">
              Connect MONEI
            </AnchorLink>{' '}
            to your PrestaShop store
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default GooglePayForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "google-pay-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
